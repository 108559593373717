<template>
  <v-app class="recover-bg">
    <div class="container-fluid auth-pos pa-10">
      <div class="row fill-height">
        <div class="col-lg-12 col-12 mx-auto my-auto">
          <div class="row">
            <div class="col-lg-12 text-center">
              <img
                src="/assets/images/brand/white-horizontal-logo.svg"
                width="240"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-8 col-lg-6 col-xl-4 mx-auto my-auto auth-glass rounded-lg pa-10">
              <div class="row no-gutters">
                <div
                  class="col-lg-12 d-flex justify-center justify-lg-end align-center"
                >
                  <v-btn
                    color="success"
                    class="rounded-pill me-2"
                    :title="$t('support')"
                    href="https://wa.me/+573195675844"
                    elevation="0"
                    outlined
                  >
                    <v-icon>mdi-whatsapp</v-icon>
                    <span>{{ $t("support") }}</span>
                  </v-btn>
                  <v-switch
                    prepend-icon="mdi-keyboard"
                    v-model="keyboardMode"
                    inset
                  ></v-switch>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 mx-auto pa-10 rounded-lg">
                  <div class="row welcome-message mb-3">
                    <div class="col-lg-12 text-center">
                      <h1 class="success--text">Recuperar contraseña</h1>
                      <p class="grey--text">
                        Por favor, digita la dirección de correo electrónico
                        para recuperar tu contraseñ.
                      </p>
                    </div>
                  </div>
                  <div class="row mb-0" v-if="responseError">
                    <div class="col-12 pb-0">
                      <v-alert type="error" color="error">{{
                        $t("Credenciales inválidas")
                      }}</v-alert>
                    </div>
                  </div>
                  <ValidationObserver v-slot="{ invalid }" ref="form">
                    <form class="v-form">
                      <div v-if="keyboardMode">
                        <div v-if="step == 1">
                          <ng-text-keyboard
                            persistent-placeholder
                            v-model="form.user"
                            :filled="false"
                            :dense="false"
                            :label="$t('email')"
                            :placeholder="$t('email')"
                            rules="required|email"
                            v-on:keyup.enter="recoverPasswd"
                          />
                          <v-btn
                            elevation="0"
                            block
                            color="success"
                            :disabled="invalid"
                            @click="recoverPasswd"
                            class="mt-0"
                          >
                            {{ $t("recover_your_password") }}
                          </v-btn>
                        </div>
                        <div v-if="step == 2" style="padding: 10px">
                          <div class="mb-4">
                            {{ $t("recovery_msg_step_2") }}
                          </div>

                          <ng-text-keyboard
                            persistent-placeholder
                            v-model="code"
                            :filled="false"
                            :dense="false"
                            :label="$t('code')"
                            :placeholder="$t('code')"
                            rules="required"
                          />
                          <v-btn
                            elevation="0"
                            block
                            :disabled="!code"
                            @click="step = 3"
                            color="success"
                            >{{ $t("continue") }}</v-btn
                          >
                        </div>

                        <div v-if="step == 3" style="padding: 10px">
                          <div class="mb-4">
                            {{ $t("recovery_msg_step_3") }}
                          </div>
                          <ng-password-keyboard
                            persistent-placeholder
                            v-model="password"
                            counter
                            :filled="false"
                            :dense="false"
                            :label="$t('password')"
                            :placeholder="$t('password')"
                            rules="required|min:8"
                            show-required="true"
                          />
                          <v-btn
                            :disabled="!password || invalid"
                            @click="changePasswd"
                            elevation="0"
                            block
                            color="success"
                            >{{ $t("change_password") }}</v-btn
                          >
                        </div>
                      </div>

                      <div v-else>
                        <div v-if="step == 1">
                          <ng-text
                            persistent-placeholder
                            v-model="form.user"
                            :filled="false"
                            :dense="false"
                            :label="$t('email')"
                            :placeholder="$t('email')"
                            rules="required|email"
                            v-on:keyup.enter="recoverPasswd"
                          />
                          <v-btn
                            elevation="0"
                            block
                            color="success"
                            :disabled="invalid"
                            @click="recoverPasswd"
                            class="mt-0"
                          >
                            {{ $t("recover_your_password") }}
                          </v-btn>
                        </div>
                        <div v-if="step == 2" style="padding: 10px">
                          <div class="mb-4">
                            {{ $t("recovery_msg_step_2") }}
                          </div>

                          <ng-text
                            persistent-placeholder
                            v-model="code"
                            :filled="false"
                            :dense="false"
                            :label="$t('code')"
                            :placeholder="$t('code')"
                            rules="required"
                          />
                          <v-btn
                            elevation="0"
                            block
                            :disabled="!code"
                            @click="step = 3"
                            color="success"
                            >{{ $t("continue") }}</v-btn
                          >
                        </div>

                        <div v-if="step == 3" style="padding: 10px">
                          <div class="mb-4">
                            {{ $t("recovery_msg_step_3") }}
                          </div>
                          <ng-passwd
                            persistent-placeholder
                            v-model="password"
                            counter
                            :filled="false"
                            :dense="false"
                            :label="$t('password')"
                            :placeholder="$t('password')"
                            rules="required|min:8"
                            show-required="true"
                          />
                          <v-btn
                            :disabled="!password || invalid"
                            @click="changePasswd"
                            elevation="0"
                            block
                            color="success"
                            >{{ $t("change_password") }}</v-btn
                          >
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                  <div class="d-block d-sm-flex mt-5">
                    <v-btn to="/" color="black" outlined class="success--text">
                      <v-icon>mdi-chevron-left</v-icon>
                      {{ $t("go_back") }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<style>
html,
body {
  overflow-y: hidden;
}

@media only screen and (max-width: 1366px) {
  html,
  body {
    overflow-y: visible;
  }
}

.recover-bg {
  height: 100% !important;
  margin-bottom: -5px;
  background-color: #f7f7f9 !important;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.52),
      rgba(0, 0, 0, 0.75)
    ),
    url("/assets/background-5-min.jpg") !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
</style>

<script>
export default {
  name: "Recover",
  data: () => ({
    keyboardMode: false,
    form: null,
    password: "",
    code: "",
    hash: "",
    step: 1,
    dialog: false,
  }),
  created() {
    /*const configFormMpn = {
      "user": {
        "name": "user",
        "label": "Usuario/Correo",
        "validate": [ "required" ],
        "prepend-inner-icon": "mdi-card-account-details",
        "required": true,
        dense: false,
        outlined: true
      },
    };*/

    this.form = {};
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
  },
  methods: {
    onDismissed() {
      this.$store.dispatch("clearError");
    },
    recoverPasswd() {
      this.$http
        .post("api/password/send-code", { email: this.form.user }, false, false)
        .then((res) => {
          if (res.success) {
            this.hash = res.hash;
            this.step = 2;
          }
        })
        .catch((e) => {});
    },
    validateCode() {
      this.$http
        .post(
          "validate-code",
          { email: this.form.user, code: this.code },
          true,
          true
        )
        .then((res) => {
          if (res.success) {
            this.step = 3;
          }
        })
        .catch((e) => {});
    },
    changePasswd() {
      let data = {
        code: this.code,
        password: this.password,
        email: this.form.user,
        hash: this.hash,
      };
      this.$http
        .post("api/password/change", data, true, false)
        .then((res) => {
          if (res.success) {
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          }
        })
        .catch((e) => {});
    },
  },
};
</script>
